import React, { useEffect, useState } from 'react';

const useIsInServer = () => {
  const [inServer, setInServer] = useState(true);

  useEffect(() => {
    setInServer(false);
  });

  return inServer;
};

export default useIsInServer;
