// responsive breakpoints
const SMALL_BREAKPOINT = 767;
const TABLET_BREAKPOINT = 768;
const LAPTOP_BREAKPOINT = 1025;
const LG_DESKTOP_BREAKPOINT = 1440;

// deprecaeted, use LG_DESKTOP_BREAKPOINT
const DESKTOP_BREAKPOINT = 992;
// colors
const HOMELIGHT_PRIMARY_COLOR = '46b6ff';

export { 
  SMALL_BREAKPOINT,
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  LAPTOP_BREAKPOINT,
  LG_DESKTOP_BREAKPOINT,
  HOMELIGHT_PRIMARY_COLOR
};
