import { useMediaQuery } from "react-responsive";
import { SMALL_BREAKPOINT, TABLET_BREAKPOINT, DESKTOP_BREAKPOINT, LAPTOP_BREAKPOINT, LG_DESKTOP_BREAKPOINT } from "../utils/constants";

export const useIsLgDesktop = () => useMediaQuery({ minWidth: LG_DESKTOP_BREAKPOINT })
export const useIsDesktop = () => useMediaQuery({ minWidth: DESKTOP_BREAKPOINT })
export const useIsLaptop = () => useMediaQuery({ minWidth: LAPTOP_BREAKPOINT })
export const useIsTablet = () => useMediaQuery({ minWidth: TABLET_BREAKPOINT, maxWidth: DESKTOP_BREAKPOINT - 1 })
export const useIsSmTablet = () => useMediaQuery({ minWidth: TABLET_BREAKPOINT, maxWidth: LAPTOP_BREAKPOINT - 1 })
export const useIsMobile = () => useMediaQuery({ maxWidth: SMALL_BREAKPOINT })

export const LgDesktop = ({ children }) => {
  return useIsLgDesktop() ? children : null
}

export const Desktop = ({ children }) => {
  return useIsDesktop() ? children : null
}

export const Laptop = ({ children }) => {
  return useIsLaptop() ? children : null
}

export const SmTablet = ({ children }) => {
  return useIsSmTablet() ? children : null
}

export const Tablet = ({ children }) => {
  return useIsTablet() ? children : null
}

export const Mobile = ({ children }) => {
  return useIsMobile() ? children : null
}
