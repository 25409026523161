import React from 'react'
import PropTypes from 'prop-types'

import useIsInServer from '../../hooks/useIsInServer';
import tracking from '../../components/services/tracking_service';

const HeaderLogo = props => {
  let currentPage = ''
  if (!useIsInServer()) {
    currentPage = window.location.pathname.split('/').pop() || 'homepage'
  }
  
  return (
    <a className='header-logo-component' href='/' onClick={() => tracking.trackEvent(currentPage, 'Tap', `${currentPage}_homepage_navbar_click`)}>
      <img
        alt='HomeLight Logo'
        src={props.logoUrl}
        className='logo'
        width={props.width}
        height={props.height}
      />
    </a>
  )
};

HeaderLogo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string
};

HeaderLogo.defaultProps = {
  width: '153',
  height: '30'
};

export default HeaderLogo
