import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NavMenu} from '@homelight/hl-core-ui';

const NavigationMenu = props => {
  const {
    items,
    theme,
    scrolledTheme,
    signInUrl,
    signInTracking,
    signOutTracking,
    maxWidth,
    baseElemSize,
    noWrapTrigger,
    user,
    hamburgerIconTracking,
    userAccountTracking,
    signOutPath,
  } = props;
  const [scrolled, setScrolled] = useState(false);
  const getEnv = () => {
    if (/sandbox|demo|staging|ops/.test(window.location.hostname)) {
      return 'staging';
    } else if (/local|dev/.test(window.location.hostname)) {
      return 'development';
    } else {
      return 'production';
    }
  };
  const signOutClient = () => {
    const rootFromEnv = {
      development: 'http://localhost:3001',
      staging: 'https://staging.hapi.homelight.com',
      production: 'https://hapi.homelight.com',
    };
    const env = getEnv();
    const logoutUrl = `${rootFromEnv[env]}/api/user-auth-service/user-login/logout`;
    fetch(logoutUrl, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    }).then(() => {
      window.location.reload(true);
    });
  };
  const onSignOut = () => {
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(signOutPath, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({authenticity_token: token}),
    }).then(() => {
      if (user) {
        signOutClient();
      } else {
        window.location.href = '/';
      }
    }).catch(err => {
      console.error(err);
      if (typeof window.Sentry !== 'undefined') {
        window.Sentry.captureException('Failure to sign out:', JSON.stringify(err));
      }
    });
  };
  const navMenuProps = {
    baseElemSize,
    items,
    theme,
    scrolledTheme,
    scrolled: scrolled,
    signInUrl,
    user,
    onSignOut,
  };
  const updateScrolled = () => setScrolled(window.scrollY >= 10);

  useEffect(() => {
    window.addEventListener('scroll', updateScrolled);
    updateScrolled();

    return () => window.removeEventListener('scroll', updateScrolled);
  }, []);

  return (
    <div className={`hl-core-ui-nav-menu ${scrolled ? 'scrolled' : 'not-scrolled'}`}>
      <NavMenu {...navMenuProps} />
    </div>
  );
};

NavigationMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  theme: PropTypes.string,
  scrolledTheme: PropTypes.string,
  scrolled: PropTypes.bool,
  signInUrl: PropTypes.string.isRequired,
  signInTracking: PropTypes.shape(),
  signOutTracking: PropTypes.shape(),
  maxWidth: PropTypes.string,
  baseElemSize: PropTypes.number,
  noWrapTrigger: PropTypes.bool,
  user: PropTypes.shape(),
  hamburgerIconTracking: PropTypes.shape(),
  userAccountTracking: PropTypes.shape(),
};

NavigationMenu.defaultProps = {
  baseElemSize: 10,
  theme: 'transparent',
  scrolledTheme: 'light',
  scrolled: false,
  signInUrl: '',
  signInTracking: null,
  signOutTracking: null,
  noWrapTrigger: true,
  user: null,
  hamburgerIconTracking: null,
  userAccountTracking: null
};

export default NavigationMenu;
